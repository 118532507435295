import { UseFormProps } from "@refinedev/react-hook-form";
import React, { PropsWithChildren } from "react";
import { AuthPageProps as AuthPagePropsCore, RegisterFormTypes } from "@refinedev/core";
import {
  LoginPage,
  RegisterPage,
  ForgotPasswordPage,
  UpdatePasswordPage,
  OtpCodePage,
} from "./components";
import type { BoxProps } from "@mui/material/Box";
import type { CardProps } from "@mui/material/Card";

export interface FormPropsType extends UseFormProps {
  onSubmit?: (values: RegisterFormTypes) => void;
}

export interface FormOtpPropsType extends UseFormProps {
  onSubmit?: (values: RegisterFormTypes & {otpcode?: number;}) => void;
}

export type AuthProps = AuthPagePropsCore<BoxProps, CardProps, FormPropsType> | PropsWithChildren<{
  /**
   * @description The type of the auth page.
   * @optional
   */
  type: "otp";
  /**
   * @description render a redirect to login page button node. If set to false, login button will not be rendered.
   * @optional
   */
  loginLink?: React.ReactNode;
}>


/**
 * **refine** has a default auth page form served on the `/login` route when the `authProvider` configuration is provided.
 * @see {@link https://refine.dev/docs/ui-frameworks/mui/components/mui-auth-page/} for more details.
 */
export const AuthPage: React.FC<AuthProps> = (props: AuthProps) => {
  const { type } = props;
  const renderView = () => {
    switch (type) {
      // case "register":
      //   return <RegisterPage {...props} />;
      case "forgotPassword":
        return <ForgotPasswordPage {...props} />;
      case "updatePassword":
         return <UpdatePasswordPage {...props} />;
      case "otp":
           return <OtpCodePage {...{...props}} />;
      default:
        return <LoginPage {...props} />;
    }
  };

  return <>{renderView()}</>;
};
