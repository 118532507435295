import { I18nProvider, usePermissions, useTitle } from "@refinedev/core";
import { Refine, Authenticated, useGetLocale, useSetLocale } from "@refinedev/core";

import axios, { AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import {
    ErrorComponent,
    RefineThemes,
    RefineSnackbarProvider,
    notificationProvider
} from "@refinedev/mui";

/**
 * Custom Layout
 */
import { ThemedLayoutV2 } from "./components/themedLayout";                                          
import { ThemedHeaderV2 } from "./components/themedLayout/header";                                   
import { ThemedSiderV2 } from "./components/themedLayout/sider";                                     
import { ThemedTitleV2 } from "./components/themedLayout/title";      

import { AuthPage } from "./components/pages/auth";

import { CssBaseline, GlobalStyles, ThemeProvider, createTheme } from "@mui/material";

import routerBindings, {
    NavigateToResource,
    UnsavedChangesNotifier,
    CatchAllNavigate
} from "@refinedev/react-router-v6";

//import dataProvider from "@refinedev/simple-rest";
import {dataProvider} from "./rest-data-provider/index";

import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";

import { MuiInferencer } from "@refinedev/inferencer/mui";

import  authProvider  from "./authProvider";

import { useTranslation } from "react-i18next";

/**
 * App icons
 */
import { PeopleAltOutlined} from '@mui/icons-material'
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';



/**
 * CRUD Resources
 */
import { ParametroCreate } from "./pages/parametros/create";
import { ParametroList } from "./pages/parametros/list";
import { ParametroShow } from "./pages/parametros/show";
import { ParametroEdit } from "./pages/parametros/edit";
//
import { UsuarioCreate } from "./pages/usuarios/create";
import { UsuarioList } from "./pages/usuarios/list";
import { UsuarioShow } from "./pages/usuarios/show";
import { UsuarioEdit } from "./pages/usuarios/edit";
//
import { ObraCreate } from "./pages/obras/create";
import { ObraList } from "./pages/obras/list";
import { ObraShow } from "./pages/obras/show";
import { ObraEdit } from "./pages/obras/edit";
//
import { GrupoObraCreate } from "./pages/gruposobras/create";
import { GrupoObraList } from "./pages/gruposobras/list";
import { GrupoObraShow } from "./pages/gruposobras/show";
import { GrupoObraEdit } from "./pages/gruposobras/edit";
//
import {PedidoCreate} from "./pages/pedidos/create";
import {PedidoList} from "./pages/pedidos/list";
import {PedidoShow} from "./pages/pedidos/show";
import {PedidoEdit} from "./pages/pedidos/edit";
//
import { DashboardPage } from "./pages/dashboard";

/**
 * APP Constants
 */
import {
    AppSettings
} from "./constants";
import { checkPermissionResource } from "./utils";
import { overridedLightTheme } from "./theme";


const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const token = localStorage.getItem(AppSettings.API_TOKEN_KEY);
    const tokenKey = AppSettings.API_TOKEN_KEY; // Ensure consistent variable naming

    if (token) {
        if (request.headers) {
        // Add token to existing headers
        request.headers[tokenKey] = token;
        } else {
        // Initialize headers if they don't exist
        request.headers = {
            [tokenKey]: token,
        };
        }
    }
    return request; // Return the updated request object
});


// Function that will be called to refresh authorization
const refreshAuthLogic = (failedRequest: { response: { config: { headers: { [x: string]: any; }; }; }; }) =>
    axiosInstance
        .post(`${AppSettings.API_URL}/auth/token/refresh`, {[AppSettings.API_REFRESH_TOKEN]:localStorage.getItem(AppSettings.API_REFRESH_TOKEN)})
        .then((tokenRefreshResponse) => {

            localStorage.setItem(AppSettings.API_TOKEN_KEY, tokenRefreshResponse.data.token);

            failedRequest.response.config.headers[AppSettings.API_TOKEN_KEY] = tokenRefreshResponse.data.token;

            return Promise.resolve();
        });

// Instantiate the interceptor
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic);


// /**
//  * Theme Customization - Overrinding variables
//  */
// const overridedLightTheme = createTheme({
//     ...RefineThemes.Red,
//     palette: {
//         ...RefineThemes.Red.palette,
//         primary: {
//             main: "#de393d",
//         },
//         secondary: {
//             main: "#be2e38",
//         },
//     },
//     timeLine: {
//         color: {
//             warning: "#fff7e6", 
//             default: "#e6fffb",
//             info: "#e6f7ff",
//             error: "#fff1f0",
//             success: "#f6ffed",
//         },
//         dotColor: {
//             warning: "#ffa940", // warning
//             default: "#36cfc9", // default
//             info: "#40a9ff", // info
//             error: "#ff4d4f", // error
//             success: "#73d13d", // success
//         },
//     },       
// });


const App: React.FC = () => {

    const { t, i18n } = useTranslation();
    const locale = useGetLocale();
    const changeLanguage = useSetLocale();
    const currentLocale = locale();

    const i18nProvider: I18nProvider = {
        translate: (key: string, options?: any, defaultMessage?: string) => t(key, options, defaultMessage),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    changeLanguage('pt-BR');



    return (
        <ThemeProvider theme={overridedLightTheme} >
            <CssBaseline />
            <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
            <RefineSnackbarProvider>
                <BrowserRouter>
                    <Refine
                        authProvider={authProvider}
                        routerProvider={routerBindings}
                        dataProvider={dataProvider(AppSettings.API_URL,axiosInstance)}                       
                        notificationProvider={notificationProvider}
                        i18nProvider={i18nProvider}
                        resources={[
                            {
                                name: "dashboard",                  
                                list: "/",
                                options: { 
                                    label: i18nProvider.translate("pages.dashboard.dashboard"),
                                },                                   
                                meta: {               
                                  icon: <HomeOutlinedIcon />,
                                //   hide: true                                  
                                }                       
                            },                              
                            {
                                name: "settings",
                                options: { 
                                    label: i18nProvider.translate("pages.settings.settings"),
                                },                                
                                meta: {
                                    icon: <SettingsOutlinedIcon />
                                }
                            }
                            ,
                            {
                                name: "parametros",                  
                                list: "/parametros",
                                show: "/parametros/show/:id",
                                create: "/parametros/create",
                                edit: "/parametros/edit/:id",
                                options: { 
                                    label: i18nProvider.translate("pages.parametros.parametros"),
                                },
                                meta: {
                                    parent: "settings",
                                    canDelete: true,
                                    icon: <TuneOutlinedIcon />,
                                    hide: !checkPermissionResource()
                                }                       
                            },           
                            {
                                name: "usuarios",                  
                                list: "/usuarios",
                                show: "/usuarios/show/:id",
                                create: "/usuarios/create",
                                edit: "/usuarios/edit/:id",
                                options: { 
                                    label: i18nProvider.translate("pages.usuarios.usuarios"),
                                },
                                meta: {
                                    parent: "settings",
                                    canDelete: true,
                                    icon: <PeopleAltOutlined />,
                                    hide: !checkPermissionResource()
                                }                       
                            },      
                            {
                                name: "registers",
                                options: { 
                                    label: i18nProvider.translate("pages.registers.registers"),
                                },                                                 
                                meta: {
                                    icon: <AppRegistrationOutlinedIcon />
                                }
                            },                                                                           
                            {
                                name: "gruposobras",                  
                                list: "/gruposobras",
                                show: "/gruposobras/show/:id",
                                create: "/gruposobras/create",
                                edit: "/gruposobras/edit/:id",
                                options: { 
                                    label: i18nProvider.translate("pages.gruposobras.gruposobras"),
                                },
                                meta: {
                                    parent: "registers",
                                    canDelete: true,
                                    icon: <DomainAddOutlinedIcon />,
                                    hide: !checkPermissionResource()
                                }                       
                            },                             
                            {
                                name: "obras",                  
                                list: "/obras",
                                show: "/obras/show/:id",
                                create: "/obras/create",
                                edit: "/obras/edit/:id",
                                options: { 
                                    label: i18nProvider.translate("pages.obras.obras"),
                                },                                
                                meta: {
                                    parent: "registers",                                    
                                    canDelete: true,
                                    icon: <ApartmentOutlinedIcon />,
                                    hide: !checkPermissionResource()
                                }                       
                            },    
                            {
                                name: "pedidos",                  
                                list: "/pedidos",
                                show: "/pedidos/show/:id",
                                create: "/pedidos/create",
                                edit: "/pedidos/edit/:id",
                                meta: {
                                  canDelete: false,
                                  icon: <AssignmentOutlinedIcon />
                                }                       
                            },  
                            {
                                name: "itens",                  
                                list: "/pedidos/:idpedido/itens",
                                show: "/pedidos/:idpedido/itens/show/:id",
                                create: "/pedidos/:idpedido/itens/create",
                                edit: "/pedidos/:idpedido/itens/edit/:id",
                                meta: {
                                  parent: "pedidos",
                                  canDelete: true,                
                                  icon: <NoteAltOutlinedIcon />,
                                  hide: true                                  
                                }                       
                            },                                                       
                                                       
                        ]}
                        options={{
                            syncWithLocation: true,
                            warnWhenUnsavedChanges: false,
                        }}
                    >
                        <Routes>
                                <Route
                                    element={
                                        <Authenticated
                                            key="authenticated-routes"
                                            fallback={
                                                <CatchAllNavigate to="/login" />
                                            }
                                        >
                                            <ThemedLayoutV2 Header={ThemedHeaderV2} Sider={ThemedSiderV2} Title={ThemedTitleV2}>
                                                <Outlet />
                                            </ThemedLayoutV2>
                                        </Authenticated>
                                    }
                                >
                                    <Route index element={<DashboardPage />} />

                                    <Route path="/parametros">
                                        <Route index element={<ParametroList />} />
                                        <Route
                                            path="show/:id"
                                            element={<ParametroShow />}
                                        />
                                        <Route
                                            path="edit/:id"
                                            element={<ParametroEdit />}
                                        />
                                        <Route
                                            path="create"
                                            element={<ParametroCreate />}
                                        />
                                    </Route>                                    
                                    <Route path="/usuarios">
                                        <Route index element={<UsuarioList />} />
                                        <Route
                                            path="show/:id"
                                            element={<UsuarioShow />}
                                        />
                                        <Route
                                            path="edit/:id"
                                            element={<UsuarioEdit />}
                                        />
                                        <Route
                                            path="create"
                                            element={<UsuarioCreate />}
                                        />
                                    </Route>                                    
                                    <Route path="/gruposobras">
                                        <Route index element={<GrupoObraList />} />
                                        <Route
                                            path="show/:id"
                                            element={<GrupoObraShow />}
                                        />
                                        <Route
                                            path="edit/:id"
                                            element={<GrupoObraEdit />}
                                        />
                                        <Route
                                            path="create"
                                            element={<GrupoObraCreate />}
                                        />
                                    </Route>
                                    <Route path="/obras">
                                        <Route index element={<ObraList />} />
                                        <Route
                                            path="show/:id"
                                            element={<ObraShow />}
                                        />
                                        <Route
                                            path="edit/:id"
                                            element={<ObraEdit />}
                                        />
                                        <Route
                                            path="create"
                                            element={<ObraCreate />}
                                        />
                                    </Route>
                                    <Route path="/pedidos">
                                        <Route index element={<PedidoList />} />
                                        <Route
                                            path="show/:id"
                                            element={<PedidoShow />}
                                        />
                                        <Route
                                            path="edit/:id"
                                            element={<PedidoEdit />}
                                        />
                                        <Route  
                                            path="create"
                                            element={<PedidoCreate />}
                                        />                                    
                                    </Route>  

                                    {/* <Route path="/pedidos/:id/itens">
                                        <Route index element={<MuiInferencer />} />
                                        <Route
                                            path="show/:id"
                                            element={<MuiInferencer />}
                                        />
                                        <Route
                                            path="edit/:id"
                                            element={<MuiInferencer />}
                                        />
                                        <Route  
                                            path="create"
                                            element={<MuiInferencer />}
                                        />                                    
                                    </Route>   */}
                                </Route>
                                <Route
                                    element={
                                        <Authenticated 
                                            key="auth-pages"
                                            fallback={<Outlet />}>
                                            <NavigateToResource resource="dashboard"/>
                                        </Authenticated>
                                    }
                                >
                                    <Route
                                        path="/login"
                                        element={<AuthPage type="login" />}
                                    />
                                    {/* <Route
                                        path="/register"
                                        element={<AuthPage type="register" />}
                                    />       */}
                                    <Route
                                        path="/forgot-password"
                                        element={<AuthPage type="forgotPassword" />}
                                    />   
                                    <Route
                                        path="/update-password"
                                        element={<AuthPage type="updatePassword" />}
                                    />    
                                    <Route
                                        path="/otp"
                                        element={<AuthPage type="otp" />}
                                    />                                                                                                                                     
                                </Route>

                                <Route
                                    element={
                                        <Authenticated 
                                            key="catch-all"
                                            fallback={<Outlet />}>
                                            <ThemedLayoutV2 Header={ThemedHeaderV2} Sider={ThemedSiderV2} Title={ThemedTitleV2}>
                                                <Outlet />
                                            </ThemedLayoutV2>
                                        </Authenticated>
                                    }
                                >

                                <Route path="*" element={<ErrorComponent />} />
                            </Route>
                        </Routes>
                        <UnsavedChangesNotifier />
                    </Refine>
                </BrowserRouter>
            </RefineSnackbarProvider>
        </ThemeProvider>
    );
};

export default App;