import { AuthBindings as AuthBindingsRefine } from "@refinedev/core";

import {
    AppSettings
} from "./constants";

import axios from "axios";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { Buffer } from "buffer";
import { AuthActionResponse } from "@refinedev/core/dist/interfaces/bindings/auth";
window.Buffer = window.Buffer || Buffer;

type AuthBindings = AuthBindingsRefine & {
    otp: (params: any) => Promise<AuthActionResponse>;
};


const handleError = (error: any) => {
    const errorMessage = error.response?.data?.message || 'Ocorreu um erro inesperado';
    return {
        success: false,
        error: {
            message: "Erro ao efetuar o login",
            name: errorMessage,
        },
    };
};

const authProvider: AuthBindings = {
    // ---
    login: async ({ email, password }) => {
        const encodedPassword = Buffer.from(password).toString("base64");
        let user = {};

        try {
            const { data } = await axios.post(`${AppSettings.API_URL}/auth`, {username:email,password:encodedPassword});
            user = data.user;
            localStorage.setItem(AppSettings.API_TOKEN_KEY, data.token);
            localStorage.setItem(AppSettings.API_REFRESH_TOKEN, data.refreshtoken);
        
        }catch (error: any) {
            return {
                success: false,
                error: {
                    message: "Erro ao efetuar o login",
                    name: error.response.data.message
                },
            };
        };

        if (user) {
            localStorage.setItem("auth", JSON.stringify(user));      
            localStorage.setItem("otp", JSON.stringify(false));      
            
            return {
                success: true,
                redirectTo: "/otp",
            };
        }else{
            return {
                success: false,
                error: {
                    message: "Erro ao efetuar o login",
                    name: "Email ou senha inválidos!",
                },
            };
        }
    },
    // ---
    check: async () => {
        const user = localStorage.getItem("auth");
        const otp = JSON.parse(localStorage.getItem("otp")||'false');

        if (user && otp) {
            return {
                authenticated: true,
            };
        }

        return {
            authenticated: false,
            logout: true,
            redirectTo: "/login",
            error: {
                message: "Check failed",
                name: "Unauthorized",
            },
        };
    },
    // ---    
    logout: async () => {
        localStorage.removeItem(AppSettings.API_TOKEN_KEY);
        localStorage.removeItem("auth");
        return {
            success: true,
            redirectTo: "/login",
        };    
    },
    // ---    
    onError: async (error) => {
        if (error.status === 401 || error.status === 403) {
            return {
                logout: true,
                redirectTo: "/login",
                error,
            };
        }

        return {};
    },
    // ---
    getPermissions: async () => {
        const user = localStorage.getItem("auth");

        if (user) {
            const { roles,rolesid,rolesname,status_profile } = JSON.parse(user);

            return  {
                roles,rolesid,rolesname,status_profile
            };
        }

        return null;
    },
    // ---
    getIdentity: async () => {
        const user = localStorage.getItem("auth");

        if (user) {
            const { id,email, roles, name } = JSON.parse(user);

            return {
                id,
                email,
                roles,
                name: name,
                //avatar: "https://i.pravatar.cc/300",
                avatar: AccountCircleIcon.muiName
            };
        }

        return null;
    },
    // ---
    register:  async ({ email, roles }) => {
        // const user = mockUsers.find((user) => user.email === email);
        // const id = 1;

        // if (user) {
        //     return {
        //         success: false,
        //         error: {
        //             name: "Register Error",
        //             message: "User already exists",
        //         },
        //     };
        // }

        // mockUsers.push({ id, email, roles });

        // return {
        //     success: true,
        //     redirectTo: "/login",
        // };

        return {
            success: false,
            redirectTo: "/login",
            error: {
                name: "Erro ao Criar Nova Conta",
                message: "Funcionalidade indisponível. Por favor entre em contato com o administrador do sistema!",
            },
        };        
    },
    // ---
    forgotPassword: async ({ email,otpcode }) => {
        if(!otpcode){
            // send password reset link to the user's email address here
            try {
                const { data } = await axios.post(`${AppSettings.API_URL}/auth/request-reset`, {email:email});

                // if request is successful
                return {
                    success: true,
                    redirectTo: "/login",
                };
            
            }catch (error: any) {
                return {
                    success: false,
                    error: {
                        message: "Erro ao solicitar nova senha de acesso",
                        name: error.response.data.message
                    },
                };
            };
        }else{
            const user = localStorage.getItem("auth")|| '';;
            const { email } = JSON.parse(user);
                
            const token = localStorage.getItem(AppSettings.API_TOKEN_KEY) || '';
            const tokenkey = AppSettings.API_TOKEN_KEY;
            let auth = false;
        
            const headers = {
                [tokenkey]: token,
            };
        
            const body = {
                email: email,
                otpcode: otpcode,
            };
            
            try {
                const { data } = await axios.post(`${AppSettings.API_URL}/auth/otp`, body, { headers });
                auth = data.authotp || false;
                localStorage.setItem("otp", JSON.stringify(auth));     
        
                if (!auth) {
                    return {
                        success: false,
                        authenticated: false,
                        logout: true,
                        redirectTo: "/login",
                        error: {
                            message: "Erro ao efetuar o login",
                            name: "Erro ao verificar o código de acesso único!",
                        },
                    };
                }
        
                return {
                    success: true,
                    redirectTo: "/",
                };
            } catch (error: any) {
                return handleError(error); // Reuse the error handling function
            }
        }

        // return {
        //     success: false,
        //     redirectTo: "/login",
        //     error: {
        //         name: "Erro ao acessar Esqueci minha senha",
        //         message: "Funcionalidade indisponível. Por favor entre em contato com o administrador do sistema!",
        //     },
        // };     
    },
    // ---  
    updatePassword: async ({ password, token }) => {
        // // update the user's password here
        const encodedPassword = Buffer.from(password).toString("base64");

        try {
            const { data } = await axios.post(`${AppSettings.API_URL}/auth/reset-password`, {resetToken:token,newPassword:encodedPassword});

            // if request is successful
            return {
                success: true,
                redirectTo: "/login",
            };
        
        }catch (error: any) {
            return {
                success: false,
                error: {
                    message: "Erro ao cadastrar a nova senha de acesso",
                    name: error.response.data.message
                },
            };
        };

        // return {
        //     success: false, 
        //     redirectTo: "/login",
        //     error: {
        //         name: "Erro ao acessar Mudar minha senha",
        //         message: "Funcionalidade indisponível. Por favor entre em contato com o administrador do sistema!",
        //     },
        // };           
    },
    // --- 
    otp : async ({ otpcode }) => {
        const user = localStorage.getItem("auth")|| '';;
        const { email } = JSON.parse(user);
            
        const token = localStorage.getItem(AppSettings.API_TOKEN_KEY) || '';
        const tokenkey = AppSettings.API_TOKEN_KEY;
        let auth = false;
    
        const headers = {
            [tokenkey]: token,
        };
    
        const body = {
            email: email,
            otpcode: otpcode,
        };
        
    
        try {
            const { data } = await axios.post(`${AppSettings.API_URL}/auth/otp`, body, { headers });
            auth = data.authotp || false;
            localStorage.setItem("otp", JSON.stringify(auth));     
    
            if (!auth) {
                return {
                    success: false,
                    authenticated: false,
                    logout: true,
                    redirectTo: "/login",
                    error: {
                        message: "Erro ao efetuar o login",
                        name: "Erro ao verificar o código de acesso único!",
                    },
                };
            }
    
            return {
                success: true,
                redirectTo: "/",
            };
        } catch (error: any) {
            return handleError(error); // Reuse the error handling function
        }
    },   
    // ---             
};






export default authProvider;